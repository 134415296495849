import api from '@services/api'

export default {
  get(axios, params, config) {
    return api.get(axios, '/account/registration/new', params, config)
  },

  register(axios, params, payload, config) {
    return api.post(axios, '/account/registration', params, payload, config)
  },

  cancel(axios, params, config) {
    return api.get(axios, '/account/registration/cancel', params, config)
  },
}
