<template lang="pug">
  main#main.account-register.d-flex.flex-grow-1.justify-content-center
    b-container
      b-row
        b-col.mx-auto(sm='12' md='10' lg='8' xl='6')
          section.l-section
            .l-section-h
              b-card.card-transparent
                .pb-5.text-center
                  .wc-i.wc-symbol.rounded(style='width: 80px; height: 80px')
                  .h4.m-0.mt-2.text-uppercase.text-white
                    | {{ $wc.conf.base.name }}

                wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

                b-form.form-wc.form-register.mt-4(
                  @submit.stop.prevent='formSubmit'
                  @input.stop.prevent='formChange'
                  @reset.stop.prevent='formReset')

                  transition(name='fade')
                    div(v-if='formShow')
                      b-form-group#form-account-email.form-input-icon(
                        :label="$t('session.placeholders.email')"
                        label-for='account-email'
                        label-sr-only)
                        i.input-icon.text-primary.fal.fa-fw.fa-envelope
                        b-form-input#account-email.form-input-first.is-required(
                          name='email'
                          type='email'
                          autocomplete='email'
                          :placeholder="$t('session.placeholders.email')"
                          :state='formValidationState($v.form.data.attributes.email)'
                          @focus.native='formChange'
                          v-model='$v.form.data.attributes.email.$model'
                          aria-describedby='account-email-feedback'
                          trim)
                        wc-forms-if#account-email-feedback(
                          extra-class='ml-feedback'
                          :attribute='$v.form.data.attributes.email'
                          :remote="formRemoteInvalidFeedback('email')"
                          :validators='{ required: {}, regExp: {}, maxLength: {} }')

                      b-form-group#form-account-password.form-input-icon(
                        :label="$t('session.placeholders.password')"
                        label-for='account-password'
                        label-sr-only)
                        i.input-icon.text-primary.fal.fa-fw.fa-lock
                        b-form-input#account-password.form-input-middle.is-required(
                          name='password'
                          type='password'
                          autocomplete='password'
                          :placeholder="$t('session.placeholders.password')"
                          :state='formValidationState($v.form.data.attributes.password)'
                          @focus.native='formChange'
                          v-model='$v.form.data.attributes.password.$model'
                          aria-describedby='account-password-feedback'
                          trim)
                        wc-forms-if#account-password-feedback(
                          extra-class='ml-feedback'
                          :attribute='$v.form.data.attributes.password'
                          :remote="formRemoteInvalidFeedback('password')"
                          :validators='{ required: {}, minLength: {}, maxLength: {} }')

                      b-form-group#form-account-password_confirmation.form-input-icon(
                        :label="$t('session.placeholders.password_confirmation')"
                        label-for='account-password_confirmation'
                        label-sr-only)
                        i.input-icon.text-primary.fal.fa-fw.fa-lock
                        b-form-input#account-password_confirmation.form-input-last.is-required(
                          name='password_confirmation'
                          type='password'
                          autocomplete='password-confirmation'
                          :placeholder="$t('session.placeholders.password_confirmation')"
                          :state='formValidationState($v.form.data.attributes.password_confirmation)'
                          @focus.native='formChange'
                          v-model='$v.form.data.attributes.password_confirmation.$model'
                          aria-describedby='password_confirmation-feedback'
                          trim)
                        wc-forms-if#account-password_confirmation-feedback(
                          extra-class='ml-feedback'
                          :attribute='$v.form.data.attributes.password_confirmation'
                          :remote="formRemoteInvalidFeedback('password_confirmation')"
                          :validators='{ required: {}, sameAsPassword: {}, minLength: {}, maxLength: {} }')

                      b-form-group#form-account-acceptance(
                        :label="$t('account.placeholders.acceptance')"
                        label-for='account-acceptance'
                        label-sr-only)
                        b-form-checkbox#account-acceptance.is-required(
                          name='acceptance'
                          :state='formValidationState($v.form.data.attributes.acceptance)'
                          v-model='$v.form.data.attributes.acceptance.$model'
                          aria-describedby='account-acceptance-feedback'
                          :value='true'
                          :unchecked-value='false'
                          switch)
                          small.text-dark
                            | {{ $t('account.placeholders.acceptance') }}
                        wc-forms-if#account-acceptance-feedback(
                          extra-class='ml-feedback'
                          :attribute='$v.form.data.attributes.acceptance'
                          :remote="formRemoteInvalidFeedback('acceptance')"
                          :validators='{ required: {}, acceptance: {} }')

                      b-button.mt-4(
                        type='submit'
                        variant='secondary'
                        :disabled="$v.form.data.$invalid"
                        block)
                        i.fal.fa-fw.fa-user-plus
                        |  {{ $t('session.sign_up') }}

                .mt-5.help.text-center.small
                  b-link(:to="{ name: 'login' }")
                    span.text-body
                      i.fal.fa-fw.fa-question-circle
                      |  {{ $t('session.help.sign_in') }}
                    span.text-capitalize
                      |  {{ $t('session.sign_in') }}

                loading(backgroundRadius='2rem' :active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import { Form } from '@common/form'
import apiRegistration from '@services/api/account/registration'
import { regExp } from '@common/form/validations'
import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'account-register',
  mixins: [Form],
  computed: {
    apiParams() {
      return {
        get: [],
        register: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            email: {
              required,
              regExp: regExp(this.$wc.conf.regex.email_browser),
              maxLength: maxLength(this.$wc.conf.limits.max_email),
              remote: () => this.formRemoteValid('email'),
            },
            password: {
              required,
              minLength: minLength(this.$wc.conf.limits.min_password),
              maxLength: maxLength(this.$wc.conf.limits.max_password),
              remote: () => this.formRemoteValid('password'),
            },
            password_confirmation: {
              required,
              sameAsPassword: sameAs(() => {
                return this.form.data.attributes.password
              }),
              minLength: minLength(this.$wc.conf.limits.min_password),
              maxLength: maxLength(this.$wc.conf.limits.max_password),
              remote: () => this.formRemoteValid('password_confirmation'),
            },
            acceptance: {
              required,
              acceptance: sameAs(() => true),
              remote: () => this.formRemoteValid('acceptance'),
            },
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiRegistration,
      apiModel: 'account',
      skipFormFillNull: false,
      formBootstrapDefaults: {
        register: { data: { attributes: { remember_me: this.$wc.conf.live.is_mobile } } },
      },
    }
  },
}
</script>
